import React, {useEffect, useRef, useState} from "react";
import '../styles/capture.css';
import '../styles/slider.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import GertyLogo from "../images/gerty-blue.png";
import getGeoInfo from "../components/autoPhoto/GeoLoc";
import axios from "axios";
import CryptoJS from 'crypto-js';

function AutoFlujo(props) {
  const [inputValue, setInputValue] = useState('');
  const [dniType, setDniType] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [defaultStringValue, setDefaultStringValue] = useState('Clinica Prueba');
  const [flowType, setFlowType] = useState("1")
  const country = useRef("MX")
  const [checkUrl, setCheckUrl] = useState()
  const [recepcionUrl, setRecepcionUrl] = useState()
  const [autoVerificationURL, setAutoVerificationURL] = useState()
  const [error, setError] = useState(false)
  const [successStatus, setSuccessStatus] = useState()
  const statusUrl = useRef("")

  useEffect(async () => {
    await getGeoInfo((val) => {
      country.current = val;
      setSelectedCountry(val)
    })
  }, [])

  useEffect(() => {
    switch (selectedCountry) {
      case "CL":
        setDniType("rut")
        break
      case "CO":
        setDniType("nuip")
        break
      case "EC":
        setDniType("nui")
        break
      case "MX":
        setDniType("rfc")
        break
      default:
      //pass
    }
  }, [selectedCountry])

  useEffect(() => {
    statusUrl.current = checkUrl
  }, [checkUrl]);

  useEffect(() => {
    const fetchData = async () => {
      if (!statusUrl.current)
        return
      axios({
        url: statusUrl.current,
        method: 'GET'
      }).then((res) => {
        setSuccessStatus(res.data.success ? "Aprobada" : "Pendiente");
        if (res.data.success)
          statusUrl.current = ""
      }).catch((error) => {
      })
    };
    const interval = setInterval(fetchData, 3000);

    return () => clearInterval(interval);
  }, []);

  function rutFormat(string) {
    string = string.replaceAll("-", "");
    if (string.length > 7) { // agrego el guion cuando hay 8 chars o más
      string = string.substring(0, string.length - 1) + "-" + string.substring(string.length - 1);
    }
    return string
  }

  const handleInputChange = (e) => {
    let value = e.target.value
    if (dniType === "rut") {
      value = rutFormat(value)
    }
    setInputValue(value);
  };

  const handleDocumentSelect = (e) => {
    setDniType(e.target.value);
  };

  const handleCountrySelect = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleDefaultInputChange = (e) => {
    setDefaultStringValue(e.target.value);
  };
  
  const handleFlowTypeChange = (newFlowType) => {
    setFlowType(newFlowType);
  };

  function generarJWT(payload) {
    let payloadObject = {...payload};
    const now = Math.floor(Date.now() / 1000);
    payloadObject['iss'] = 'example_issuer'
    payloadObject['iat'] = now
    payloadObject['exp'] = now + 3600

    const header = {
      alg: "HS256",
      typ: "JWT"
    };

    const exposedKey = '12345678901234567890123456789012';

    const encodedHeader = btoa(JSON.stringify(header));
    const encodedPayload = btoa(JSON.stringify(payloadObject));

    const msj = `${encodedHeader}.${encodedPayload}`
    const signature = CryptoJS.HmacSHA256(msj, exposedKey).toString(CryptoJS.enc.Base64)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, ''); // Firma en formato URL-safe

    const token = `${encodedHeader}.${encodedPayload}.${signature}`;
    return token;
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false)
    setCheckUrl()
    setSuccessStatus("Pendiente")
    let formdata = {
      'dni_verify': inputValue.replaceAll(".", ""),
      'dni_type': dniType,
      'dni_solicitor': "TesterSolicitor",
      'country_iso': selectedCountry,
      'provider': defaultStringValue,
      'gerty_id_flow': flowType,
    }
    // const formDataObj = {};
    // for (const [key, value] of formdata.entries()) {
    //   formDataObj[key] = value;
    // }
    // console.log("data enviada a back", formDataObj)
    axios({
      url: process.env.REACT_APP_BACKEND_URL + "/api/v1/verifications",
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": generarJWT(formdata),
      //   TODO esto es una herramienta de desarrollo, esto nunca debe salir a producción
      },
      data: formdata
    }).then((res) => {
        if (res.status !== 201) {
          throw new Error('No se pudo crear la cuenta')
        }
        setCheckUrl(res.data.url_result)
        setRecepcionUrl(res.data.url_web)
        setAutoVerificationURL(res.data.url_email)
      }
    ).catch(err => {
      setError(true)
    })
  };


  const renderDocType = () => {

    if (dniType === "rut") {
      return "RUT"
    } else if (dniType === "nuip") {
      return "NUIP"
    } else if (dniType === "nui") {
      return "N° Cédula"
    } else if (dniType === "rfc") {
      return "CURP"
    } else {
      return "DNI"
    }
  }

  return (
    <div className="container overflow-y">
      <img className='gerty-verificate-validar'
           src={GertyLogo}
           alt="GertyId Logo"/>
      <div>
        <h1 className="title-capture">Genererador de validación de identidad</h1>
        <p>Esto es un ambiente de pruebas</p>
      </div>

      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label col-4">Ingrese {renderDocType()} a validar:</label>
            <input className="col-6" type="text" value={inputValue} onChange={handleInputChange}/>
          </div>

          {/*<div className="form-group">*/}
          {/*  <label className="form-label col-4">Seleccione un tipo de documento:</label>*/}
          {/*  <select className="col-6" value={dniType} onChange={handleDocumentSelect}>*/}
          {/*    <option value="">Seleccione...</option>*/}
          {/*    <option value="rut">RUT</option>*/}
          {/*    <option value="rfc">CURP</option>*/}
          {/*    <option value="nuip">NUIP</option>*/}
          {/*    <option value="nui">NUI</option>*/}
          {/*  </select>*/}
          {/*</div>*/}

          <div className="form-group">
            <label className="control-label form-label col-4">Seleccione país de la persona:</label>
            <select className="col-6" value={selectedCountry} onChange={handleCountrySelect}>
              <option value="">Seleccione...</option>
              <option value="CL">Chile</option>
              <option value="MX">México</option>
              <option value="CO">Colombia</option>
              <option value="EC">Ecuador</option>
            </select>
          </div>

          <div className="form-group">
            <label className="form-label col-4">Clínica ficticia:</label>
            <input className="col-6" type="text" value={defaultStringValue} onChange={handleDefaultInputChange}/>
          </div>

          <div className="form-group d-flex align-items-center justify-content-center">
            <label className="control-label form-label col-4">Tipo flujo:</label>
            <div className="col-6 d-flex align-items-center justify-content-start">
              <div className="mr-5">
                <input
                  type="radio"
                  value="0"
                  checked={flowType === "0"}
                  onChange={() => handleFlowTypeChange("0")}
                />
                <label>Clásico</label>
              </div>
              <div className="mr-3">
                <input
                  type="radio"
                  value="1"
                  checked={flowType === "1"}
                  onChange={() => handleFlowTypeChange("1")}
                />
                <label>Liveness-Básico</label>
              </div>
            </div>
          </div>

          <button className="mt-3" type="submit">Generar Verificación</button>
        </form>
      </div>
      {
        checkUrl &&
        <>
          <h6 className="mt-3">{successStatus}</h6>
          {
            successStatus == "Aprobada" ?
              <h3>Una vez aprobada, se debe generar otra verificación</h3>
              : null
          }
          <div className="form-group mt-4">
            <div>Link para <b>autovalidación</b></div>
            <a href={autoVerificationURL} target="_blank" rel="noopener noreferrer">
              Auto validación
              https//...{autoVerificationURL ? autoVerificationURL.substr(autoVerificationURL.length - 5) : ""}
            </a>
          </div>
          <div className="form-group mt-4">
            <div>Validación como si la hiciera la <b>recepciónista</b></div>
            <a href={recepcionUrl} target="_blank" rel="noopener noreferrer">
              Simula que te valida la recepción
              https//...{recepcionUrl ? recepcionUrl.substr(recepcionUrl.length - 5) : ""}
            </a>
          </div>
          <div className="form-group mt-4">
            <div>Estado de verificación</div>
            <div>{successStatus}</div>
            <h3>Una vez aprobada, se debe generar otra verificación</h3>
          </div>
        </>
      }
      {error &&
        <div>
          <h1>Hubo un error en la generación, revise la coherencia de los datos</h1>
        </div>
      }
    </div>
  );
};

export default AutoFlujo;