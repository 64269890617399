import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Label } from 'reactstrap';
import moment from 'moment';
import '../../styles/validation.css'
import Loading from "../loading";

export default function InsureeForm (props) {

  const[loading, setLoading]= useState('');
  const [message] = useState('');
  const max_date = moment(new Date()).format('YYYY-MM-DD');

  const handleSubmit = (values) => {
    setLoading(true);
    props.data.certified_email = values.certified_email
    props.data.birthdate = values.birthdate
    props.data.serie_number = values.serie_number
    props.data.manual_reason = values.manual_reason
    props.next(props.data, 8, setLoading)
  }

  const renderDocType = () => {
    const country = props.data.document_country
    if (country === "CL") {
      return "rut"
    } else if (country === "CO") {
      return "nuip"
    } else if (country === "EC") {
      return "N° Cédula"
    } else if (country === "MX") {
      return "curp"
    }
  }

  const renderDni = () => {
    const country = props.data.document_country
    if (country === "CL" && props.data.formatted_rut) {
      return props.data.formatted_rut
    } else {
      return props.data.dni
    }
  }

  useEffect(() => {
    if (!props.data.is_manual_verification) {
      return props.prev(props.data, 0)
    }
  }, [props]);

  if(loading) {
    return <Loading />
  }

  return (
    <div className="container-validation__manual-validation">
      <h1 className="titulo-register">Datos a Verificar</h1>
      <p>Recuerda que estás verificando el {renderDocType()} <br></br><strong>{renderDni()}</strong></p>
      <Formik
        initialValues={
          {
            certified_email: props.data.certified_email,
            birthdate: props.data.birthdate,
            serie_number: props.data.serie_number,
            manual_reason: props.data.manual_reason
          }
        }
        validationSchema={
          Yup.object({
            certified_email: Yup.string().email('Ingrese un correo valido')
              .required('Este campo es obligatorio'),
            birthdate: Yup.string()
              .required('Este campo es obligatorio'),
            serie_number: props.data.document_country === "CL" ? Yup.string().required('Este campo es obligatorio') : Yup.string(),
            manual_reason: Yup.string()
              .required('Este campo es obligatorio'),
          })
        }
        onSubmit={
          async (values) => {
            handleSubmit(values)
          }
        }
      >
        {({ errors, touched }) => (
          <Form>
            <div className="field-form-register">
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className={ errors.certified_email && touched.certified_email ? "input-group-error field" : "field" }>
                    <Label className='input-label'>Correo electrónico</Label>
                    <Field
                      type="email"
                      className="input"
                      name="certified_email"
                      placeholder="cliente@mail.com"
                    />
                    {
                      errors.certified_email && touched.certified_email && (
                        <div className="error-input-register no-padding">{errors.certified_email}</div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className={ errors.birthdate && touched.birthdate ? "input-group-error field" : "field" }>
                    <Label className='input-label'>Fecha de nacimiento</Label>
                    <Field
                      type="date"
                      className="input"
                      max={max_date}
                      name="birthdate"
                      placeholder="Fecha de nacimiento"
                    />
                    {
                      errors.birthdate && touched.birthdate && (
                        <div className="error-input-register no-padding">{errors.birthdate}</div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className={ errors.serie_number && touched.serie_number ? "input-group-error field" : "field" }>
                    <Label className='input-label'>{props.data.document_country === "CL" ? "Número documento": "Número documento (si corresponde al país)"}</Label>
                    <Field
                      className="input"
                      name="serie_number"
                      placeholder={props.data.document_country === "CL" ? "000.000.000 ó A000000000" : ""}
                    />
                    {
                      errors.serie_number && touched.serie_number && (
                        <div className="error-input-register no-padding">{errors.serie_number}</div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className={ errors.manual_reason && touched.manual_reason ? "input-group-error field" : "field" }>
                    <Label className='input-label'>Motivo de validación manual</Label>
                    <Field
                      as="textarea"
                      className="input reason-textarea"
                      name="manual_reason"
                    />
                    {
                      errors.manual_reason && touched.manual_reason && (
                        <div className="error-input-register no-padding">{errors.manual_reason}</div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="button is-link">Enviar</button>
          </Form>
        )}
      </Formik>
      <p>{message}</p>
    </div>
  );
}