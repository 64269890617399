import React, {useEffect, useRef, useState} from "react";
import '../../styles/validation.css'
import GertyLogo from "../../images/gerty-blue.png";

export default function SignCanvas(props) {
  const [accepted, setAccepted] = useState(false);
  const canvasRef = useRef(null);

  const handleClear = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    setAccepted(false);
  };

  const handleSubmit = () => {
    if (!accepted) {
      alert('Debe aceptar los términos y condiciones antes de continuar.');
      return;
    }
    const canvas = canvasRef.current;
    props.data.sign_image = canvas.toDataURL().split(',')[1];
    props.agreeTerms(true)
    props.next(props.data, 0)
  }

  function resizeCanvas(canvas) {
    const {width, height} = canvas.getBoundingClientRect()
    const {devicePixelRatio: ratio = 1} = window
    const aux_width = width * ratio
    const aux_height = height * ratio
    if (Math.abs(canvas.width - aux_width) / aux_width > 0.01 || Math.abs(canvas.height - aux_height) / aux_height > 0.01) {
      const context = canvas.getContext('2d')
      canvas.width = width * ratio
      canvas.height = height * ratio
      context.scale(ratio, ratio)
      return true
    }

    return false
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    resizeCanvas(canvas)
    let drawing = false;


    const updateAcceptedState = () => {
      const pixelData = context.getImageData(0, 0, canvas.width, canvas.height).data;
      setAccepted(pixelData.some((pixel) => pixel !== 0));
    };

    const getTouchPosition = (touchEvent) => {
      const rect = canvas.getBoundingClientRect();
      return {
        offsetX: touchEvent.touches[0].clientX - rect.left,
        offsetY: touchEvent.touches[0].clientY - rect.top
      };
    }

    const startDrawing = (nativeEvent) => {
      drawing = true;
      draw(nativeEvent);
    };

    const startDrawingTouch = (touchEvent) => {
      const pos = getTouchPosition(touchEvent);
      drawing = true;
      draw({offsetX: pos.offsetX, offsetY: pos.offsetY});
      touchEvent.preventDefault();  // Prevenir desplazamiento de la página
    };

    const finishDrawing = () => {
      drawing = false;
      context.beginPath();
      updateAcceptedState()
    };

    const draw = (nativeEvent) => {
      if (!drawing) return;
      context.lineWidth = 2;
      context.lineCap = "round";
      context.strokeStyle = "black";

      const {offsetX, offsetY} = nativeEvent;
      context.lineTo(offsetX, offsetY);
      context.stroke();
      context.beginPath();
      context.moveTo(offsetX, offsetY);
      resizeCanvas(canvas)
    };

    const drawTouch = (touchEvent) => {
      if (!drawing) return;
      const pos = getTouchPosition(touchEvent);
      draw({offsetX: pos.offsetX, offsetY: pos.offsetY});
      touchEvent.preventDefault();  // Prevenir desplazamiento de la página
    };

    canvas.addEventListener("mousedown", startDrawing);
    canvas.addEventListener("mouseup", finishDrawing);
    canvas.addEventListener("mousemove", draw);

    canvas.addEventListener("touchstart", startDrawingTouch);
    canvas.addEventListener("touchend", finishDrawing);
    canvas.addEventListener("touchmove", drawTouch);

    // Limpieza de listeners al desmontar el componente
    return () => {
      canvas.removeEventListener("mousedown", startDrawing);
      canvas.removeEventListener("mouseup", finishDrawing);
      canvas.removeEventListener("mousemove", draw);

      canvas.removeEventListener("touchstart", startDrawingTouch);
      canvas.removeEventListener("touchend", finishDrawing);
      canvas.removeEventListener("touchmove", drawTouch);
    };
  }, []);


  const terms_url = "/Política de Privacidad Healthatom pacientes (RV Alessandri 09-05-2024)(975963.2).pdf";

  return (
    <div>
      <img className='gerty-verificate-validar'
           src={GertyLogo}
           alt="GertyId Logo"/>

      <h1 className="titulo-verificar-pendiente">Términos y Condiciones</h1>
      <h3 className="titulo-centro-verificar-pendiente-validar">
        Declaro aceptar expresamente la <a href={terms_url} target="_blank" rel="noopener noreferrer">
        Política de Privacidad</a> para el tratamiento de mis datos biométricos
      </h3>

      <div className="sign_container">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="flex-grow-1 text-center">
            <p className="signature-prompt mb-0">Firma aquí:</p>
          </div>
          <button className="button btn-info mb-2" onClick={handleClear}>Limpiar</button>
        </div>
        <canvas className="signature-canvas"
                ref={canvasRef}
        ></canvas>
      </div>
      <div>
        <button className="button is-link" id="button-signin" onClick={handleSubmit}>Continuar</button>
      </div>

    </div>
  );
}