import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Introduccion from '../../components/validacion/VerificarIdentidad';
import AlmostReady from '../../components/validacionRut/AlmostReady';
import VerificationComplete from '../../components/validacionRut/VerificationComplete';
import VerificationFailed from '../../components/validacionRut/VerificationFailedID';
import ManualValidationFirstForm from '../../components/manualValidation/InsureeForm';
import ManualValidationSecondForm from '../../components/manualValidation/ValidationResponsibleForm';
import AlertInfoRut from '../../components/AlertInfoRut';
import getAccessToken from '../../utils/getAccessToken';
import VerificationState from '../../components/VerificationState';
import CamaraPermission from "../../components/CamaraPermission";
import { format } from 'rut.js';
import ChoiceCountry from "./choice_country";
import ChoiceBackOldOrNew from "./choice_back_old_or_new";
import EvaluationStatus from "../evaluation/EvaluationStatus";
import EvaluationDetails from "../evaluation/EvaluationDetails";
import EvaluationPaymentDetails from "../evaluation/EvaluationPaymentDetails";
import getGeoInfo from "./GeoLoc";
import {AwsLiveness} from "../liveness/AwsLiveness";
import CheckBoxTerms from "../sign_terms/checkBoxTerms";
import SignCanvas from "../sign_terms/signCanvas";

export default function ValidarIdentidadFotosAutomaticas(props) {

  const [carnetNuevo, setCarnetNuevo] = useState(0);
  const [country, setCountry] = useState("")
  const [termsAgreed, setTermsAgreed] = useState(false)
  let [page, _setPage] = useState(0);
  const [goBackToPage, setGoBackToPage] = useState("");
  const [data, setData] = useState({
    face_image: '',
    liveness_id: '',
    frontal_image: props.data.required_document === false ? "True" : "",
    reverse_image: props.data.required_document === false ? "True" : "",
    rut: props.data.rut ? props.data.rut : "",
    dni: props.data.dni ? props.data.dni : "",
    dni_type: props.data.dni_type ? props.data.dni_type : "",
    formatted_rut: format(props.data.rut),
    sign_image: "",
    emotion: props.data.emotion ? props.data.emotion : "",
    emotion_english: props.data.emotion_english ? props.data.emotion_english : "",
    emotion_image: '',
    certified_email: '',
    birthdate: '',
    serie_number: '',
    manual_reason: '',
    name_responsible: '',
    // rut_responsible: '',
    dni_responsible: '',
    document_country: '',
    agreed: false,
    is_manual_verification: props.data.is_manual_verification,
    is_face_successful: props.data.is_face_successful ? props.data.is_face_successful : false,
    token: props.data.token,
    usage: props.data.usage,
    commerce_name: props.data.commerce_name,
    redirect_to: props.data.redirect_to,
    reception: props.data.reception,
  });

  const setPage = (update) => {
    if (typeof update === 'function') {
      _setPage((prev) => {
        let currentIndex;
        if (typeof prev === 'number') {
          currentIndex = prev;
        }
        else if (typeof prev === 'string') {
          currentIndex = stepOrder.indexOf(prev);
        }
        return update(currentIndex);
      });
    } else {
      _setPage(update);
    }
  };

  useEffect(() => {
    // tratamos de definir el pais al cargar el componente
    if (props.country_selected) {
      setCountry(props.country_selected)
      setCarnetNuevo("locked") // si es /= 0 , bloquea la seleccion de pais
    } else {
      setCountry("CL")  // si no hay pais asumiremos que era un dato legacy, por tanto, de chile
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    data.document_country = country
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  const sendInsuree = async (data, callback) => {
    let token = ""
    token = await getAccessToken
    let formdata = new FormData()
    formdata.append('certified_email', data.certified_email)
    formdata.append('birthdate', data.birthdate)
    formdata.append('serie_number', data.serie_number)
    formdata.append('manual_reason', data.manual_reason)
    formdata.append('token', data.token)
    formdata.append('document_country', data.document_country)
    setData(prev => ({...prev, ...data}))
    await axios({
      url:  process.env.REACT_APP_BACKEND_URL + "/manual_verification/insuree/",
      method: 'POST',
      headers: {
        "Content-Type": "multidata/form-data",
        "Authorization": "Bearer " + token
      },
      data: formdata
    })
    .then ((res) => {
      if (res.status !== 200) {
        throw new Error('No se pudo guardar los datos')
      }
      setPage("MANUAL_VALIDATION_FORM2")
    })
    .catch(err =>{
      setData(prev => ({...prev, ...data}))
      callback(false)
      setPage("MANUAL_VALIDATION_FORM1")
    })
  }

  const sendResponsible = async (data, callback) => {
    let token = ""
    token = await getAccessToken
    let formdata = new FormData()
    formdata.append('name_responsible', data.name_responsible)
    // formdata.append('rut_responsible', data.rut_responsible)
    formdata.append('dni_responsible', data.dni_responsible)
    formdata.append('dni_type', data.dni_type)
    formdata.append('token', data.token)
    formdata.append('document_country', data.document_country)
    setData(prev => ({...prev, ...data}))
    await axios({
      url:  process.env.REACT_APP_BACKEND_URL + "/manual_verification/responsible/",
      method: 'POST',
      headers: {
        "Content-Type": "multidata/form-data",
        "Authorization": "Bearer " + token
      },
      data: formdata
    })
    .then ((res) => {
      if (res.status !== 200) {
        throw new Error('No se pudo guardar los datos')
      }
      setPage("VERIFICATION_COMPLETE")
    })
    .catch(err =>{
      setData(prev => ({...prev, ...data}))
      callback(false)
      setPage("MANUAL_VALIDATION_FORM2")
    })
  }

  // useEffect(()=>{
  //   console.log("data")
  //   console.log(data)
  // },[data])
  // useEffect(()=>{
  //   console.log("page")
  //   console.log(page)
  // },[page])

  // un efecto para que se ejecute cuando cambie page para chequear si se aceptaron los terminos y condiciones
  useEffect(()=>{
    // podría moverse a nextPage pero se debe manejar el cambio de termsAgreed antes del rerender de la pagina
    const agreeType = data.is_manual_verification ? 'SIGN_CANVAS' : 'CHECK_TERMS'

    if (!termsAgreed &&
      ((typeof page === 'string' &&
        stepOrder.indexOf(page) > stepOrder.indexOf(agreeType)) ||
      (typeof page === 'number' &&
        page > stepOrder.indexOf(agreeType)))
    ) {
      setPage(agreeType)
      return
    }
  },[page])

  const nextPage = async (newData, step = 0, callback = null) => {
    // console.log("in NextPage, step: ", step)
    setData(prev => ({...prev, ...newData}))

    // if (step !== 6) {
    //   setPage("VERIFICATION_COMPLETE")
    //   return;
    // }

    if (step === 0) {
      setGoBackToPage("INTRODUCTION")
      newData.rut = props.data.rut;
      newData.dni = props.data.dni;
      newData.dni_type = props.data.dni_type;
      setData(prev => ({...prev, ...newData}))
      if (data.is_face_successful && !data.is_manual_verification) {
        // se cumplió la comparacion de rostros con el carnet pero no el liveness
        // la imagen ya está en el attempt
        newData.frontal_image = "True";
        newData.reverse_image = "True";
        setData(prev => ({...prev, ...newData}))
        setPage("ALMOST_READY")
      }
      else if (props.data.required_document === false) {
        setGoBackToPage("ALMOST_READY")
        setPage("ALMOST_READY")
      } else {
        // console.log("page +1")
        setPage(prev => prev + 1)
      }
    }

    else if (step === 1) {
              // console.log("page +1")
      setPage(prev => prev + 1)
    }

    else if (step === 2){
      // console.log("hacer request a:" + process.env.REACT_APP_BACKEND_URL + "/validation/faces_verification/")
      let formdata = new FormData()
      formdata.append('carnet_image', newData.frontal_image)
      formdata.append('carnet_backside_image', newData.reverse_image)
      formdata.append('face_image', newData.face_image)
      formdata.append('liveness_id', newData.liveness_id)
      formdata.append('sign_image', newData.sign_image)
      formdata.append('false_negatives', newData.false_negatives)
      formdata.append('qr_false_negatives', newData.qr_false_negatives)
      formdata.append('rut', newData.rut)
      formdata.append('dni', newData.dni)
      formdata.append('dni_type', newData.dni_type)
      formdata.append('token', props.data.token)
      formdata.append('manual_verification', data.is_manual_verification)
      formdata.append('star_coords', data.star_coords)
      formdata.append('flag_coords', data.flag_coords)
      formdata.append('qr_coords', data.qr_coords)
      formdata.append('automaticPhoto', data.automaticPhoto)
      formdata.append('QRautomaticPhoto', data.QRautomaticPhoto)
      formdata.append('document_country', data.document_country)
      formdata.append('reception', data.reception)
      setData(prev => ({...prev, ...newData}))
      if (data.is_manual_verification === true) {
        // console.log("es manualll")
        setPage("ALERT_INFO_RUT")
      }
      // const formDataObj = {};
      // for (const [key, value] of formdata.entries()) {
      //   formDataObj[key] = value;
      // }
      // console.log("data enviada a back", formDataObj)
      await axios({
        url:  process.env.REACT_APP_BACKEND_URL + "/validation/faces_verification/", // provar con puerto :80/validation/faces_verification/
        method: 'POST',
        headers: {
          "Content-Type": "multidata/form-data",
        },
        data: formdata
      })
      .then ((res) => {
        // console.log("----res----")
        // console.log(res)
        // console.log("--res end--")
        if (res.status !== 200) {
          throw new Error('No se pudo crear la cuenta')
        }
        if (res.data.validate) {
          setPage("VERIFICATION_COMPLETE");
        } else {
          if (props.data.required_document === false) {
            setGoBackToPage("ALMOST_READY")
          } else {
            setGoBackToPage("CHOICE_COUNTRY")  // si hubo un error en este punto, debemos tomar de nuevo todas las imagenes
          }
          setPage("VERIFICATION_FAILED")
        }
      })
      .catch(err =>{
        if (props.data.required_document === false) {
          setGoBackToPage("ALMOST_READY")
        } else {
          setGoBackToPage("CHOICE_COUNTRY")
        }
        setPage("VERIFICATION_FAILED")
      })
    }

    else if (step === 6) {
      sendResponsible(newData, callback)
    }

    else if (step === 7) {
      setPage("MANUAL_VALIDATION_FORM1")
    }

    else if (step === 8) {
      sendInsuree(newData, callback)
    }

    else if (step === 10) {
      newData.rut = props.data.rut;
      newData.dni = props.data.dni;
      newData.dni_type = props.data.dni_type;
      setData(prev => ({...prev, ...newData}))
      if (props.data.required_document === false) {
        setPage("ALMOST_READY")
      } else {
        setPage("CHOICE_COUNTRY")
      }
    }
  }

  const prevPage = (newData, valor = 0) => {
    setData(prev => ({...prev, ...newData}))
    if (valor === 0) {
      setPage(prev => prev - 1)
    }
    else {
      setPage(valor)
      setGoBackToPage("INTRODUCTION")  // siempre reiniciamos el comportamiento de prevPage
    }
  }

  const nextEvaluationDetails = () => {
    setPage("EVALUATION_DETAILS")
  }

  const nextEvaluationPaymentDetails = () => {
    setPage("EVALUATION_PAYMENT_DETAILS")
  }

  const steps = (page) => {
    if (typeof page === 'number' && page >= 0 && page < stepOrder.length) {
      return stepComponents[stepOrder[page]];
    } else if (typeof page == 'string' && stepComponents.hasOwnProperty(page)) {
      return stepComponents[page];
    }

    return "Página de pasos no encontrada";
  }

  // con esto podemos agregar nuevos componentes sin afectar el llamado por indice
  // antes si se agrega un componente antes del 7 había que modificar todos los setPage(7)
  const stepComponents = {
     INTRODUCTION: <Introduccion next={nextPage} data={data} />,
     SIGN_CANVAS: <SignCanvas next={nextPage} data={data} agreeTerms={setTermsAgreed}/>,
     CHECK_TERMS: <CheckBoxTerms next={nextPage} data={data} agreeTerms={setTermsAgreed} />,
     CHOICE_COUNTRY: <ChoiceCountry prev={prevPage} data={data} next={nextPage} carnetNuevo={carnetNuevo} setCarnetNuevo={setCarnetNuevo} country={country} setCountry={setCountry} />,
     CHOICE_BACK_OLD_OR_NEW: <ChoiceBackOldOrNew prev={prevPage} data={data} next={nextPage} carnetNuevo={carnetNuevo} setCarnetNuevo={setCarnetNuevo} />,
     ALMOST_READY: <AlmostReady prev={prevPage} data={data} next={nextPage} flow={props.flow} />,
     AWS_LIVENESS: <AwsLiveness prev={prevPage} next={nextPage} data={data} />,
     VERIFICATION_FAILED: <VerificationFailed prev={prevPage} next={nextPage} goBackToPage={goBackToPage} data={data} />,
     VERIFICATION_COMPLETE: <VerificationComplete prev={prevPage} next={nextPage} nextEvaluationDetails={nextEvaluationDetails} data={data} />,
     MANUAL_VALIDATION_FORM1: <ManualValidationFirstForm prev={prevPage} next={nextPage} data={data} />,
     MANUAL_VALIDATION_FORM2: <ManualValidationSecondForm prev={prevPage} next={nextPage} data={data} />,
     ALERT_INFO_RUT: <AlertInfoRut prev={prevPage} next={nextPage} data={data} />,
     CAMERA_PERMISSION: <CamaraPermission next={nextPage} data={data} />,
     REMOVED_ACTION: <div>Removed action</div>,
     EVALUATION_STATUS: <EvaluationStatus prev={prevPage} next={nextPage} data={data} />,
     EVALUATION_DETAILS: <EvaluationDetails next={nextEvaluationPaymentDetails} data={data} />,
     EVALUATION_PAYMENT_DETAILS: <EvaluationPaymentDetails data={data} />
   };

  // con esto podemos manejar facilmente el orden con un (prev) => {prev + 1}
  const stepOrder = [
     'INTRODUCTION',
     data.is_manual_verification ? 'SIGN_CANVAS' : 'CHECK_TERMS',
     'CHOICE_COUNTRY',  //1
     'CHOICE_BACK_OLD_OR_NEW',
     'ALMOST_READY',
     'AWS_LIVENESS',
     'VERIFICATION_FAILED', // 5
     'VERIFICATION_COMPLETE', // 6
     'MANUAL_VALIDATION_FORM1',
     'MANUAL_VALIDATION_FORM2',
     'ALERT_INFO_RUT',
     'CAMERA_PERMISSION',
     'REMOVED_ACTION',
     'EVALUATION_STATUS',
     'EVALUATION_DETAILS',
     'EVALUATION_PAYMENT_DETAILS' // 14
   ];
  let aux_page = typeof page === 'string' ? stepOrder.indexOf(page) : page
  if (aux_page >= stepOrder.indexOf('EVALUATION_PAYMENT_DETAILS')) {
    // Flujo información de evaluación
    // Cedemos toda maquetación a componentes
    return (
      <>
        {steps(page)}
      </>
    )
  }

  return (
    <div className="container-validation">
      <VerificationState page={page} rut={data.formatted_rut}  dni={data.dni}  dni_type={data.dni_type} stepOrder={stepOrder}/>
      <div className="container">
        {steps(page)}
      </div>
    </div>
  );
}