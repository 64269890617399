import React, { useState, useEffect } from "react";
import '../../styles/capture.css';
import Loading from '../loading';
import SwitchCameraIcon from "../../images/switch-camera-icon.png";
import {Camera} from "react-camera-pro";

const TakePicture = (props) => {
  const [showImgCapture, setShowImgCapture] = useState(true);
  const [loading, setLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState(null);
  const INITIAL_FACING_MODE = props.data.is_manual_verification ? "environment" : "user";

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      setImgSrc(webcamRef.current.takePhoto());
      setShowImgCapture(false);
    },
    [webcamRef]
  );

  const handleSubmit = () => {
    setLoading(true);
    props.data.face_image = imgSrc.split(',')[1];
    props.next(props.data, 2)
  }

  const onBack = () => {
    setShowImgCapture(true);
    setImgSrc(null);
  }

  const renderTitle = () => {
    if(props.data.is_manual_verification){
      return <h1 className='title-capture'>Toma una foto del dueño del documento de identidad</h1>
    }else{
      return <h1 className='title-capture'>Tómate una foto</h1>
    }
  }

  const renderSubtitle = () => {
    if(props.data.is_manual_verification){
      return <h1 className='subtitle-capture' id="take-picture-face">Ubica su rostro dentro del círculo.</h1>
    }else{
      return <h1 className='subtitle-capture' id="take-picture-face">Ubica tu rostro dentro del círculo.</h1>
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => { setLoading(false); }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
    <div className='background-capture' hidden={loading}>
      {showImgCapture && (
        <div>
          {renderTitle()}
          {renderSubtitle()}
        </div>
      )}
      {imgSrc && (
        <div>
          <h1 className='title-capture'>Revisa la imagen</h1>
          <h1 className='subtitle-capture' id="take-picture-face">Asegúrate de que la imagen se vea bien.</h1>
        </div>
      )}
      <div className="capture">
        {showImgCapture && (
          <>
            <div className="full-container-face">
              <div className="borde1" style={{width: '100%', height: '100%'}}>
                <div className="filtro-circular1"/>
              </div>
              <Camera
                ref={webcamRef}
                aspectRatio={1}
                facingMode={INITIAL_FACING_MODE}
                errorMessages={{
                  noCameraAccessible: 'No se puede acceder a la cámara. Por favor refresque y conceda permisos de cámara.\nPor favor conecte su cámara o pruebe un Navegador diferente.',
                  permissionDenied: 'Permiso de cámara denegado. Por favor refresque y conceda permisos de cámara',
                  switchCamera: 'La cámara seleccionada no es compatible o solo hay una cámara disponible.',
                  canvas: 'No se puede acceder a la cámara o al lienzo.'
                }}
              />
            </div>
            <div className="capture-buttons-container face-capture">
              <button className="button button-capture" id="button-picture-face" onClick={capture}>Tomar Foto</button>
              <img className="button-switch-camera" onClick={() => webcamRef.current.switchCamera()} src={SwitchCameraIcon} title="Cambiar cámara" alt="Cambiar cámara" />
            </div>
          </>
          )}
        {imgSrc && (
          <div>
            <img className="rounded-face-img" src={imgSrc} alt="captured-img"/>
            <div>  
              <button className="button button-again-capture face-btn" onClick={onBack}>
                Volver a tomar foto
              </button>
            </div>
            <div>
              <button className="button button-contine-capture" onClick={handleSubmit}>Continuar</button>
            </div>
          </div>
        )}
      </div>
    </div>
    <div hidden={!loading}>
      <Loading/>
    </div>
    </>
  );
};

export default TakePicture;