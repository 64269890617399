import React, { useState, useEffect } from 'react';
import NoVerificationFound from '../components/validacion/NoVerficationFound';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import getAccessToken from '../utils/getAccessToken';
import Loading from "../components/loading";
import ValidarIdentidadFotosAutomaticas from "../components/autoPhoto/ValidarIdentidadFotosAutomaticas";
import ValidarIdentidadFotosAutomaticasSl from "../components/autoPhoto/ValidarIdentidadFotosAutomaticasSl";

export default function LoginUrl (props) {
  const [verification, setVerification] = useState(false);
  const [pending, setPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState()
  const [flow, setFlow] = useState(0)
  const [data, setData]= useState({
    token: '',
    rut: '',
    dni: '',
    dni_type: '',
    commerce_name: '',
    document_country: '',
    is_guest: '',
    is_manual_verification: true,
    reception: true,
  });
  const { currentUser, handleUserLogout } = useAuth();

  useEffect(() => {
    if (!loading) {
      async function handleLoad() {
        setLoading(true)
        try {
          let token = await getAccessToken
          const formdata = new FormData();
          formdata.append('details', props.match.params.details)
          const response = await axios({
            method: 'POST',
            url: process.env.REACT_APP_BACKEND_URL + "/verification_dm/login_details/",
            mode: "no-cors",
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": "Bearer " + token
            },
            data: formdata
          })
          setCountry(response.data.verification.document_country.toUpperCase())
          setData({
            ...data,
            rut: response.data.rut,
            dni: response.data.dni,
            dni_type: response.data.dni_type,
            document_country: response.data.verification.document_country,
            token: response.data.verification.token,
            commerce_name: response.data.verification.commerce_name,
            is_guest: response.data.verification.is_guest,
            required_document: response.data.required_document,
            usage: response.data.verification.usage,
            redirect_to: response.data.verification.redirect_to
          })
          setFlow(response.data.verification.gerty_id_flow)
          setVerification(true)
          return response;
        } catch (error) {
          setPending(true)
          return error;
        }
      }
      handleLoad();
    }
  }, [loading, data, props])

  if (currentUser) {
    handleUserLogout();
  }

  if (pending) {
    return <NoVerificationFound />
  } 
  if (verification) {
    switch (flow) {
      case 0:
        // classic - sin liveness
        return <ValidarIdentidadFotosAutomaticasSl data={data} country_selected={country} flow={flow}/>;
      case 1:
        return <ValidarIdentidadFotosAutomaticas data={data} country_selected={country} flow={flow}/>;
      default:
        return <ValidarIdentidadFotosAutomaticas data={data} country_selected={country} flow={flow}/>
    }
  }

  return (
    <div className="container-validation">
      <div className="container">
        <Loading />
      </div>
    </div>
  )
}