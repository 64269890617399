import React, {useState} from "react";
import '../../styles/validation.css'
import GertyLogo from "../../images/gerty-blue.png";

export default function CheckBoxTerms(props) {
  const [accepted, setAccepted] = useState(false);

  const handleSubmit = () => {
    if (!accepted) {
      alert('Debe aceptar los términos y condiciones antes de continuar.');
      return;
    }
    props.agreeTerms(true)
    props.next(props.data, 0)
  }

  const terms_url = "/Política de Privacidad Healthatom pacientes (RV Alessandri 09-05-2024)(975963.2).pdf";

  return (
    <div>
      <img className='gerty-verificate-validar'
           src={GertyLogo}
           alt="GertyId Logo"/>

      <h1 className="titulo-verificar-pendiente">Términos y Condiciones</h1>
      <div className="terms-checkbox">
        <input
          type="checkbox"
          id="terms"
          checked={accepted}
          onChange={() => setAccepted(!accepted)}
          className="terms-checkbox__input"
        />
        <label for="terms" className="terms-checkbox__text">
          Declaro aceptar expresamente la <a href={terms_url} target="_blank" rel="noopener noreferrer">
            Política de Privacidad</a> para el tratamiento de mis datos biométricos
        </label>
      </div>
      <div>
        <button className="button is-link" id="button-signin" disabled={!accepted} onClick={handleSubmit}>Continuar</button>
      </div>

    </div>
  );
}